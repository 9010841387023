.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height:100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.box {
  background-color: #f7f7f7;
  border-radius: 4px;
  border: solid 1px #f4f4f4;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1)
}
